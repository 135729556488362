










import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class GradientText extends Vue {
  @Prop({ required: true }) index!: number
  @Prop({ required: true }) title!: string
  @Prop({ required: true }) description!: string
  @Prop({ required: true }) source!: string
}
