





import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class GradientText extends Vue {
  @Prop({ required: false }) smaller?: boolean
}
