












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Card from '@/components/Card.vue'

@Component({
  components: { Card },
})
export default class RuleAndImage extends Vue {
  @Prop({ required: true }) index!: number
  @Prop({ required: false }) left?: boolean
}
