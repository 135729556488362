



















import Vue from 'vue'
import { i18n } from '@/plugins/i18n'
import Component from 'vue-class-component'
import GradientText from '@/components/GradientText.vue'
import MainSubtitle from '@/components/MainSubtitle.vue'
import RuleAndImage from '@/components/RuleAndImage.vue'
import RuleList from '@/components/RuleList.vue'
import { namespace } from 'vuex-class'

const AppStore = namespace('AppStore')

@Component({
  components: {
    GradientText,
    MainSubtitle,
    RuleAndImage,
    RuleList,
  },
})
export default class App extends Vue {
  @AppStore.Mutation setLanguage!: (language: string) => void

  created() {
    document.title = i18n.t('title') as string
  }

  get metaInfo() {
    return {
      title: i18n.t('title') as string,
    }
  }
}
