












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Card from '@/components/Card.vue'

@Component({
  components: { Card },
})
export default class RuleList extends Vue {
  @Prop({ required: true }) cardIndexArray!: number[]
}
